<script>
import Layout from "../../../layouts/main";

import PageHeader from "@/components/page-header";

import store from "@/state/store";
import {apiSeoMethods} from "@/state/helpers";
import {helpers, required} from "@vuelidate/validators";
import Swal from 'sweetalert2'
import useVuelidate from "@vuelidate/core";

/**
 * Editors component
 */
  
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      menuId: 500200,
      menuCode: "seo",

      form: [],
      formSubmitted: false,
    };
  },
  created() {
    this.$changeMenu(this.menuId, this.menuCode)
  },
  mounted () {
    this.loadData()
  },
  validations: function() {
    return {
      form: {
        title: {
          required: helpers.withMessage("제목을 입력해 주세요.", required),
        },
        description: {
          required: helpers.withMessage("설명을 입력해 주세요.", required)
        },
        keywords: {
          required: helpers.withMessage("키워드를 입력해 주세요.", required)
        },
      }
    }
  },
  methods: {
    ...apiSeoMethods,

    // 데이터 로딩
    loadData() {
        store.dispatch("apiSeo/load").then((result) => {
            let data = result.data.data
            
            this.form = data
            this.textaeraResize()
        })
    },


    // 게시글 저장 서버요청
    saveData() {
      store.dispatch('notification/clear')

      // stop here if form is invalid
      this.v$.$touch();

      this.formSubmitted = true

      if (this.v$.form.$invalid) {
        this.formSubmitted = false
      } else {
        let params = {
          "uid" : this.form.uid,
          "title" : this.form.title,
          "description" : this.form.description,
          "keywords" : this.form.keywords
        }

        store.dispatch("apiSeo/store", params).then((result) => {
          this.formSubmitted = false
          Swal.fire(result.message, "", result.success ? "success" : "error");
          // window.location.reload()
        }, () => {
          this.formSubmitted = false
        });
      }
    },
    textaeraResize() {
      this.$textarea.style.height = '1px'
      this.$textarea.style.height = `${this.$textarea.scrollHeight + 30}px`
    }
  },
  computed: {
    $textarea() {
      return document.getElementById('textarea')
    },
    nowMetaTags() {
        return "<meta name='title' content='"+this.form.title+"' />\n"+
        "<meta name='description' content='"+this.form.description+"' />\n"+
        "<meta name='keywords' content='"+this.form.keywords+"' />\n"+
        "<meta property='og:type' content='website'/>\n"+
        "<meta property='og:title' content='"+this.form.title+"'/>\n"+
        "<meta property='og:description' content='"+this.form.description+"'/>\n"+
        "<meta property='og:url' content='https://dowajwo.com/'/>\n"+
        "<meta name='twitter:card' content='summary'/>\n"+
        "<meta name='twitter:title' content='"+this.form.title+"'/>\n"+
        "<meta name='twitter:description' content='"+this.form.description+"'/>\n"+"<meta name='twitter:url' content='https://dowajwo.com/'/>"
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader/>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="mb-2">현재 SEO</div>
            <div class="card border border-primary p-1 mb-2">
              <textarea v-model="nowMetaTags" disabled style="height:auto" id="textarea"></textarea>
            </div>
            <b-form  @submit.prevent="saveData" enctype="multipart/form-data">
                <b-form-group
                    class="mb-3"
                    label="제목(title)"
                    label-for="title"

                    :invalid-feedback="v$.form.title.$error ? v$.form.title.$errors[0].$message : null"
                    :state="!v$.form.title.$error"
                >
                    <b-form-input
                    type="text"
                    v-model="form.title"

                    :state="!v$.form.title.$error"
                    :disabled="formSubmitted"
                    ></b-form-input>
                </b-form-group>

                <b-form-group
                    class="mb-3"
                    label="설명(description)"
                    label-for="description"

                    :invalid-feedback="v$.form.description.$error ? v$.form.description.$errors[0].$message : null"
                    :state="!v$.form.description.$error"
                >
                    <b-form-input
                    type="text"
                    v-model="form.description"

                    :state="!v$.form.description.$error"
                    :disabled="formSubmitted"
                    ></b-form-input>
                </b-form-group>

                <b-form-group
                    class="mb-3"
                    label="키워드(keyword)"
                    label-for="keywords"

                    :invalid-feedback="v$.form.keywords.$error ? v$.form.keywords.$errors[0].$message : null"
                    :state="!v$.form.keywords.$error"
                >
                    <b-form-input
                    type="text"
                    v-model="form.keywords"

                    :state="!v$.form.keywords.$error"
                    :disabled="formSubmitted"
                    ></b-form-input>
                </b-form-group>

                <div class="text-center">
                    <b-button variant="primary" type="submit" :disabled="formSubmitted">
                      <span>수정</span>
                    </b-button>
                </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
